import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import {
  createBrowserRouter,
  RouterProvider,
  ScrollRestoration,
} from "react-router-dom";
import { Provider } from 'react-redux';
import {store} from '@/store/store'
import { ApolloProvider } from '@apollo/client';
import client from '@/service/ApolloEnvironment';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ThemeProvider } from "@/components/theme-provider"
import { BlogContent } from '@/blog/AllBlogs';
import LoadingComponent from '@/components/Loading';
import ErrorBoundary from '@/blog/ErrorBoundary';
const CustomDomains = React.lazy(() => import('@/layout/custom-domains'));
const AccountPage = React.lazy(() => import('@/account'));
const HomeComponent = React.lazy(() => import('@/layout/home/HomeComponent'));
const HomeSite = React.lazy(() => import('@/layout/site'));
const HomePage = React.lazy(() => import('@/layout/home'));
const TermsAndConditions = React.lazy(() => import('@/layout/site/terms-and-condition'));
const PrivacyPolicy = React.lazy(() => import('@/layout/site/privacy-policy'));
const Clicks = React.lazy(() => import('@/layout/clicks'));
const BillingPlan = React.lazy(() => import('@/account/settings/BillingPlan'));
const ProfileDetails = React.lazy(() => import('@/account/account-details'));
const ContactUsForm = React.lazy(() => import('@/layout/contact-us'));
const ShortedUrls = React.lazy(() => import('@/layout/home/ShortedUrls'));
const Referrer = React.lazy(() => import('@/layout/analytics/Referrer'));
const ShortCodeNotFound = React.lazy(() => import('@/layout/short-code-not-found'));
const Blog = React.lazy(() => import('@/blog'));
const AllBlogs = React.lazy(() => import('@/blog/AllBlogs'));
const ErrorPage = React.lazy(() => import('@/ErrorPage'));
const LoginPage = React.lazy(() => import('@/account/login'));
const Register = React.lazy(() => import('@/account/register'));


const router = createBrowserRouter([
  {
    path: "/",
    element: <HomeSite />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/app",
    element: <HomePage />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "/app/contact-us",
        element: <ContactUsForm />
      },
      {
        path: "/app/urls",
        element: <ShortedUrls />
      },
      {
        path: "/app/branded-domains",
        element: <CustomDomains />
      },
      {
        path: "/app/account",
        element: <AccountPage />,
        children: [
          {
            path: "/app/account/plan",
            element: <BillingPlan />
          },
          {
            path: "/app/account",
            element: <ProfileDetails />
          }
        ]
      },
      {
        path: "/app",
        element: <HomeComponent />
      },
      {
        path: "/app/clicks",
        element: <Clicks />
      },
      {
        path: "/app/analytics",
        element: <Referrer />
      }
    ]
  },
  {
    path: "/code-not-found",
    element: <ShortCodeNotFound />
  },
  {
    path: "/terms-and-condition",
    element: <TermsAndConditions />
  },
  {
    path: "/privacy-policy",
    element: <PrivacyPolicy />
  },
  {
    path: "/account/login",
    element: <LoginPage />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/account/register",
    element: <Register />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/blog",
    element: <AllBlogs />,
    children: [
      {
        path: "/blog",
        element: <BlogContent />
      },
      {
        path: "/blog/:slug",
        element: <Blog />
      }
    ]
  }
]);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <ApolloProvider client={client}>
      <Provider store={store}>
        <ThemeProvider defaultTheme='dark'>
          <ErrorBoundary>
            <React.Suspense fallback={<LoadingComponent />}>
            <div>
              <RouterProvider router={router} />
              <ToastContainer />
            </div>
            </React.Suspense>
          </ErrorBoundary>
        </ThemeProvider>
      </Provider>
    </ApolloProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
